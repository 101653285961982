<template>
  <div id="app">
    <div class="ns">{{ candidate.name }}</div>
    <div class="info">
      <span>{{ candidate.location }}</span>
      <span><a :href="'mailto:' + candidate.mail">{{ candidate.mail }}</a></span>
      <span><a :href="'tel:' + candidate.phone">{{ candidate.phone }}</a></span>
    </div>
    <div class="ph">Summary</div>
    <div class="summary"> 
      {{ candidate.summary }}
    </div>
    <div class="ph">Experience</div>
    <div v-for="(exp, expIndex) in candidate.exp" :key="'exp_'+ expIndex" class="exp">
      <div class="company"><span v-html="getOutput(exp.company)"></span> <span class="location">{{ exp.location }}</span></div>
      <div v-for="(role, roleIndex) in exp.roles" :key="'role_'+roleIndex" class="role">
        <div class="title">Role: {{role.name}} <span>{{ role.begin }} - {{ role.end }}</span> </div>
        <ul class="ach-list">
          <li v-for="(ach, achIndex) in role.achievements" :key="'ach_' +achIndex" v-html="getOutput(ach)">
            
          </li>
        </ul>
      </div>
      <div v-for="(extra, extraIndex) in exp.extras" :key="'ext_'+ extraIndex" class="extra">
        <span class="title">{{ extraIndex }} : </span> <span v-html="getOutput(extra)"></span>
      </div>
    </div>
    
    <div class="ph">Education</div>
    <div v-for="(edu, eduIndex) in candidate.edu" :key="'edu_'+ eduIndex" class="exp">
      <div class="education">
        <div class="date"><span>{{ edu.begin }}</span><span v-if="edu.end != null"> / {{edu.end}}</span></div>
        <span v-html="getOutput(edu.education +' <span class=\'from\'>from</span> ' + edu.location)"></span> 
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  methods: {
    getOutput(phrase) {
      for (var a in this.glossary) {
        phrase = phrase.replace(a, '<a href="'+this.glossary[a]+'" target="_blank">'+a+'</a>')
      }
      return phrase;
    }
  },
  data() {
    return {
      glossary: {
        "SunExpress Airlines": "https://sunexpress.com",
        "Electronic Flight Bag": "https://en.wikipedia.org/wiki/Electronic_flight_bag",
        "Odeon Tours": "https://www.odeontours.com",
        "Wezyr Holidays": "https://online.coraltravel.pl/",
        "Coral Travel": "https://online.coral.ru",
        "Coral Travel Ukraine": "https://online.coraltravel.ua",
        "Sunmar": "https://online.sunmar.ru",
        "BlueSky": "http://bluesky.ru/",
        "Odeon Tourism International Holding": "https://www.otiholding.com/",
        "Booklogic": "https://booklogic.net/",
        "ACARS": "https://tr.wikipedia.org/wiki/ACARS",
        "Protobuf": "https://github.com/protocolbuffers/protobuf",
        "Line Training": "http://www.bfa.lt/line-training.html",
        "Progressive Web App": "https://web.dev/progressive-web-apps/",
        "Executive Management": "https://simplicable.com/new/executive-management",
        "cockpit": "https://en.wikipedia.org/wiki/Cockpit",
        "cabin": "https://en.wikipedia.org/wiki/Aircraft_cabin",
        "process management": "https://en.wikipedia.org/wiki/Process_management_(computing)",
        "crew member": "https://en.wikipedia.org/wiki/Crewman",
        "Saber Software Solutions": "https://sabersoft.com.tr",
        "student information system": "https://en.wikipedia.org/wiki/Student_information_system",
        "Node.JS": "https://nodejs.org/en/",
        "PHP": "https://www.php.net/",
        ".Net Framework": "https://tr.wikipedia.org/wiki/.NET_Framework",
        "C#": "https://tr.wikipedia.org/wiki/C_Sharp",
        ".Net Core": "https://docs.microsoft.com/en-us/dotnet/core/introduction",
        "MsSQL": "https://www.microsoft.com/en-us/sql-server/sql-server-2019",
        "ReactJS": "https://reactjs.org/",
        "VueJS": "https://vuejs.org/",
        "Oracle": "https://www.oracle.com/database/",
        "Java": "https://en.wikipedia.org/wiki/Java_(programming_language)",
        "Cordova": "https://cordova.apache.org/",
        "MySQL": "https://tr.wikipedia.org/wiki/MySQL",
        "Elastic Search": "https://www.elastic.co/",
        "Redis": "https://redis.io/",
        "Nginx": "https://www.nginx.com/",
        "Vanilla Script": "http://vanilla-js.com/",
        "Visual Basic": "https://tr.wikipedia.org/wiki/Visual_Basic",
        "Cold Fusion": "https://en.wikipedia.org/wiki/ColdFusion_Markup_Language",
        "flightscanner.com.tr": "https://flightscanner.com.tr",
        "carscanner.com.tr": "https://carscanner.com.tr",
        "hotelscanner.com.tr": "https://hotelscanner.com.tr",
        "microservice": "https://microservices.io/patterns/microservices.html",
        "OvB": "https://ovbsistemi.com/",
        "Dart": "https://dart.dev/",
        "Flutter": "https://flutter.dev/",
        "Mobile": "https://en.wikipedia.org/wiki/Mobile_device",
        "Scrum.org": "https://www.scrum.org/",
        "The Open Group": "https://www.opengroup.org/",
        "Dokuz Eylul University": "https://www.deu.edu.tr/",
        "Bileydi Anatolian High School": "https://bileydi.meb.k12.tr/"
      },
      candidate: {
        name: "Emir Emre Sarbay",
        location: "Antalya, Turkey",
        phone: "+90 535 447 74 70",
        mail: "emre@sarbay.com",
        summary: "As a senior software developer (and a former lead) i am enthusiastic about programming, eager to learn new technologies, seeking to work with professional and open minded teams.",
        exp: [
          {
            company: "SunExpress Airlines",
            location: "Antalya, Turkey",
            roles: [
              {
                begin: "2018-03",
                end: "Present",
                name: "Senior Software Developer",
                achievements: [
                  "Integrated Electronic Flight Bag (EFB) functionalities depending on the requirements of Flight Operations.",
                  "Implemented Air to Ground Messaging via ACARS and IP (Protobuf) Channels.",
                  "Implemented Notification logic with alert and reminders mechanisms of latest internal announcements for both Ground and Flight Operations.",
                  "Developed digital process of Simulator and Line Training evaluations (for both cockpit and cabin crew), including Progressive Web App support for offline usage.",
                  "Developed a process management tool from scratch, to fit the requirements of Executive Management's requirements.",
                  "Developed the crew portal project that provides crew members to track their individual duties and latest internals about their daily flight operation processes."
                ]
              }
            ],
            extras: {
              "Tech": ".Net Framework(C#), .Net Core, MsSQL, ReactJS, VueJS, Oracle, Java"
            }
          }, {
            company: "Booklogic",
            location: "Istanbul, Turkey",
            roles: [
              {
                begin: "2017-03",
                end: '2018-03',
                name: "Senior Software Developer (Remote)",
                achievements: [
                  "Developed B2C Web and Mobile projects for car rentals, flight tickets and hotel accomodations.",
                  "Designed and developed flightscanner.com.tr, contributed to development of hotelscanner.com.tr and carscanner.com.tr and built CarScanner Mobile Application.",
                  "Managed the data flow from over 20 suppliers, and processed millions of rows to use in B2B and B2C projects, such as geographical data, actual hotel prices, flight prices and provided multi-language support.",
                  "Developed microservices for the company's various needs, such as notification jobs, continuous integrations and deployments."
                ]
              }
            ],
            extras: {
              "Tech": "Node.JS, ReactJS, PHP, MsSQL, Elastic Search, Cold Fusion, Mobile (Cordova)"
            }
          }, {
            company: "Saber Software Solutions",
            location: "Antalya, Turkey",
            roles: [
              {
                begin: "2014-02",
                end: '2017-03',
                name: "Co-Founder, Full Stack Developer",
                achievements: [
                  "Developed a custom framework for both web sites and office projects, which had been used by over 20 clients.",
                  "Developed a student information system named (OvB) for schools which they easily manage their student's attendances, announcements, schedules, homeworks, examinations, albums, accounting etc. Being used by over 150 clients, and the parents of the students' of schools.",
                  "Developed a student transportation tracking system for the transport drivers to interact with the parents of the students, when the students are going to school or coming to home. "
                ]
              }
            ],
            extras: {
              "Tech": "PHP, MySQL, Redis, Nginx, Vanilla Script, Mobile (Cordova), Dart (Flutter)"
            }
          }, {
            company: "Odeon Tourism International Holding",
            location: "Antalya, Turkey",
            roles: [
              {
                begin: "2011-12",
                end: '2014-02',
                name: "Web Development Team Leader",
                achievements: [
                  "Analyzed and led the team to develop the BlueSky (Moscow) Agency project.",
                  "Led the team and contributed to development of Odeon Tours Hotel Information System project, including Reservation Confirmation and Hotel Contract Management modules.",
                  "Led the team to develop various incoming-operator data implementation services.",
                  "Managed the Odeon Tours B2C project."
                ]
              },
              {
                begin: "2010-01",
                end: '2011-12',
                name: "Senior Software Developer",
                achievements: [
                  "Analyzed, developed and fine-tuned the B2B-Agency project for Odeon Tourism International Holding's Operator Associates (including Coral Travel, Wezyr Holidays, Coral Travel Ukraine, Sunmar Moscow) which has been visited by over 10.000 agencies daily.",
                  "Analyzed, developed the agency accounting module for the B2B-Operator project.",
                  "Implemented the B2C project for Sunmar Moscow including credit card payments.",
                  "Developed the Api's for 3rd party vendor interactions."
                ]
              },
              {
                begin: "2007-05",
                end: '2009-07',
                name: "Software Developer",
                achievements: [
                  "Developed and maintained Holding's Associated companies' corporate websites.",
                  "Developed a custom web framework for the Odeon Tours domestic B2B-Operator project, which also provided custom set of web components for the non-frontend developers to work with.",
                  "Analyzed and Developed the B2B-Agency project which had been used by over 1,000 agencies in Turkey, for hotel & travel reservations and credit card payments."
                ]
              }
            ],
            extras: {
              "Tech": ".Net Framework (C#), MsSQL, Vanilla Script"
            }
          }, {
            company: "Ulker Internet Services",
            location: "Antalya, Turkey",
            roles: [
              {
                begin: "2003-02",
                end: '2007-05',
                name: "Software Developer (Part-Time, Full-Time from 2006)",
                achievements: [
                  "Developed a common CMS framework to rapidly design & programme & deploy websites for over 30 clients.",
                  "Designed and Programmed websites for companies focused on tourism, e-commerce and corporate sectors.",
                ]
              }
            ],
            extras: {
              "Tech": "PHP, MySQL, Vanilla Script"
            }
          }, {
            company: "Dokuz Eylul University",
            location: "Izmir, Turkey",
            roles: [
              {
                begin: "2002-09",
                end: '2006-08',
                name: "Junior Software Developer (Scholarship)",
                achievements: [
                  "Developed and maintained the faculty's student portal, which is visited by over 4,000 students and 100 academicians daily.",
                  "Developed the student attendance tracking system, which provided a consistent and paperless attendance management for teachers.",
                  "Developed a custom forum software, depending on the requirements of student & academician relationship. "
                ]
              }
            ],
            extras: {
              "Tech": "MySQL, PHP, Vanilla Script, MsSQL, Visual Basic"
            }
          }
        ],
        edu: [{
            "begin": "2019-05",
            "end": null,
            "education": "Professional Scrum Master 1 Certification",
            "location": "Scrum.org"
          },
          {
            "begin": "2018-11",
            "end": null,
            "education": "TOGAF 9 Certification",
            "location": "The Open Group"
          },
          {
            "begin": "2001-01",
            "end": "2006-01",
            "education": "Bachelor of Social Science: Economics",
            "location": "Dokuz Eylul University"
          },
          {
            "begin": "1994-01",
            "end": "2001-01",
            "education": "English-medium high school",
            "location": "Bileydi Anatolian High School"
          } 
        ]
      }
    }
  }
}
</script>

<style lang="scss">
body {
  margin:0;
  font-family: 'Roboto', sans-serif;
  line-height: 20px;
  background-color: #f2f2f2;
}
a {
  color:#333;
}
a:hover {
  text-decoration: none;
}
#app {
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;

  .ns {
    font-size:20px;
    text-align: center;
    padding:10px;
  }
  .info {
    padding:10px;
    text-align: center;
    span {
      padding:0px 10px;
    }
  }
  .ph {
    font-style: italic;
    border-bottom:1px solid #ccc;
    color:cadetblue;
    border-color: cadetblue;
    font-weight: bold;
    padding:10px;
    background-color: #f2f2f2;
  }
  .summary {
    padding:10px;
  }
  .exp {
    margin-bottom:30px;
    padding:0px 20px 0px 20px;
    
    .company {
      height: 25px;
      line-height: 25px;
      font-weight: bold;
      .location {
        background-color: cadetblue;
        padding:5px;
        border-radius: 10px;
        font-size:10px;
        color:#fff;
      }
    }

    .role {
      padding-left:30px;
      .title {
        color:gray;
      }
    }

    .extra {
      padding-left:30px;
      color:gray;

      .title {
        color:cadetblue;
      font-size: 10px;
      font-style: italic;
      }
    }
  }
  .education {
    
      .date {
        color:gray;
        font-size:12px;
      }

      .from {
        color:gray;
      }
  }
}
</style>
